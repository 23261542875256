import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import { Analytics } from '@vercel/analytics/react';

// Lazy-loaded pages
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Programs = lazy(() => import("./pages/Programs"));
const Gallery = lazy(() => import("./pages/Gallery"));
const Contact = lazy(() => import("./pages/Contact"));
const Donate = lazy(() => import("./pages/Donate"));
const EducationForAll = lazy(() => import("./pages/EducationForAll"));
const MedicalCamps = lazy(() => import("./pages/MedicalCamps"));
const ReliefAndSupport = lazy(() => import("./pages/ReliefAndSupport"));
const WomenSkillDevelopment = lazy(() => import("./pages/WomenSkillDevelopment"));
const ChildrenEducationCenter = lazy(() => import("./pages/ChildrenEducationCenter"));
// const NotFound = lazy(() => import("./pages/NotFound")); 

// Scroll to Top on Route Change
const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Suspense fallback={<div className="loading-screen">Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/programs" element={<Programs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/education-for-all" element={<EducationForAll />} />
          <Route path="/medical-camps" element={<MedicalCamps />} />
          <Route path="/relief-and-support" element={<ReliefAndSupport />} />
          <Route path="/women-skill-development" element={<WomenSkillDevelopment />} />
          <Route path="/children-education-center" element={<ChildrenEducationCenter />} />
          {/* Catch-all for 404 Not Found */}
          {/* <Route path="*" element={<NotFound />} />  */}
        </Routes>
        <Analytics />
      </Suspense>
      
    </Router>
    
  );
};

export default App;
