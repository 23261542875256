import React, { useState } from "react";
import { Link } from "react-router-dom";
import logoImage from "../assets/sbfet.png"; 
import './Navbar.css'; 

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {/* Logo */}
        <div className="navbar-logo">
          <Link to="/">
            <img src={logoImage} alt="Logo" />
          </Link>
        </div>

        {/* Navbar Menu Items */}
        <ul className={`navbar-menu ${isMenuOpen ? "active" : ""}`}>
          <li className="navbar-item">
            <Link to="/" className="navbar-link">
              Home
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/about" className="navbar-link">
              About
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/programs" className="navbar-link">
              Programs
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/gallery" className="navbar-link">
              Gallery
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/contact" className="navbar-link">
              Contact
            </Link>
          </li>
          <li className="navbar-item navbar-donate">
            <Link to="/donate" className="donate-button">
              Donate
            </Link>
          </li>
        </ul>

        {/* Hamburger Menu Icon */}
        <div className="navbar-hamburger" onClick={toggleMenu}>
          <span className="hamburger-icon">&#9776;</span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
